<template>
  <div class="page" id="page">
    <div class="mine">
      <div class="top" style="margin-top: 80px">
        <div class="title">
          <div class="lent"></div>
          <div class="title-text">仓储式超市</div>
          <div class="lent"></div>
        </div>
        <p class="title-bottom-font">智慧缴费管理解决方案</p>
      </div>

      <div class="content">
        <div class="p-box">
          <el-image
            class="animate__animated animate__backInLeft"
            style="width: 220px; height: 400px"
            :src="require('../assets/platformp/buy.png')"
          ></el-image>

          <p class="title-d">品类繁多</p>
          <p class="d-t">
            物业前台收款中心，方便住户线下缴费，支付费用预收、临时收费，可精确查看每户的欠费情况和收费情况、包括应收、已缴、未缴账单。
            拷贝 4
          </p>
        </div>
        <div class="p-box">
          <el-image
            class="animate__animated animate__backInDown"
            style="width: 220px; height: 400px"
            :src="require('../assets/platformp/buy1.png')"
          ></el-image>
          <p class="title-d">积分抵扣物业费</p>
          <p class="d-t">
            物业前台收款中心，方便住户线下缴费，支付费用预收、临时收费，可精确查看每户的欠费情况和收费情况、包括应收、已缴、未缴账单。
            拷贝 4
          </p>
        </div>
        <div class="p-box">
          <el-image
            class="animate__animated animate__backInRight"
            style="width: 220px; height: 400px"
            :src="require('../assets/platformp/buy2.png')"
          ></el-image>
          <p class="title-d">独特新品</p>
          <p class="d-t">
            物业前台收款中心，方便住户线下缴费，支付费用预收、临时收费，可精确查看每户的欠费情况和收费情况、包括应收、已缴、未缴账单。
            拷贝 4
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  watch: {},
};
</script>
<style scoped lang="less">
.page {
  width: 100%;
  text-align: center;
  .mine {
    width: 65%;
    margin: auto;
  }

  .top {
    width: 300px;
    margin: auto;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .lent {
        width: 48px;
        height: 2px;
        background-color: #333333;
        // border: 1px solid #333333;
      }
      .title-text {
        font-weight: bold;
        color: #333333;
        font-size: 32px;
      }
    }
    .title-bottom-font {
      font-weight: 400;
      color: #999999;
      font-size: 16px;
    }
  }
  .content {
    margin: 100px 0 200px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title-d {
      margin-top: 100px;
      height: 22px;
      font-size: 22px;
      font-weight: bold;
      color: #333333;
    }
    .d-t {
      text-align: left;
      width: 372px;
      font-size: 20px;
      font-weight: 400;
      color: #999999;
      line-height: 27px;
    }
  }
}
</style>
